import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/builder"

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
const ListNews = ({ data, location, pageContext }) => {
  const blocks = buildBlocks(
    data.wpPage.blocks,
    {},
    { ...pageContext, location },
    data.wpPage.id
  )

  return (
    <Layout
      meta={data.wpPage.Meta}
      path={pageContext.pagePath}
      title={data.wpPage.title}
      slug={data.wpPage.slug}
    >
      {blocks}
    </Layout>
  )
}

export const query = graphql`
  query ($pageSlug: String) {
    wpPage(slug: { eq: $pageSlug }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`

export default ListNews
